import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/auth/services/auth.service';
import { Role } from '@app/models/Role.enum';
import { SelectedMuscle } from '@app/types';
import { ProgramConfiguration, ProgramConfigurationParametersValues, ProgramConfigurationParameters } from '@app/utils/electrostim/ProgramConfiguration';
import { ChannelProgramParameterDefinition, ChannelProgramParameterDefinitionUnit, ChannelProgramParameters } from '@app/utils/electrostim/ChannelProgramParameters';
import { DeepReadonly } from '@egzotech/exo-electrostim';
import { TranslateService } from '@ngx-translate/core';

const STIM_DURATIONS = [
  { label: '10 min', value: 10 * 60 * 1e6 },
  { label: '20 min', value: 20 * 60 * 1e6 },
  { label: '30 min', value: 30 * 60 * 1e6 },
  { label: '40 min', value: 40 * 60 * 1e6 },
  { label: '50 min', value: 50 * 60 * 1e6 },
  { label: '60 min', value: 60 * 60 * 1e6 },
  { label: '90 min', value: 90 * 60 * 1e6 },
  { label: '120 min', value: 120 * 60 * 1e6 },
];

interface DynamicChannelValue {
  value: number;
  disabled: boolean;
}

export interface DynamicChannelProgramParameterDefinition<T extends ChannelProgramParameterDefinitionUnit> {
  unit: T;
  default: number;
  values: DynamicChannelValue[];
}

interface DynamicChannel {
    runTime?: DynamicChannelProgramParameterDefinition<"us">;
    pulseFrequency?: DynamicChannelProgramParameterDefinition<"Hz">;
    pulseWidth?: DynamicChannelProgramParameterDefinition<"us">;
    riseTime?: DynamicChannelProgramParameterDefinition<"us">;
    fallTime?: DynamicChannelProgramParameterDefinition<"us">;
    offset?: DynamicChannelProgramParameterDefinition<"us">;
    pauseTime?: DynamicChannelProgramParameterDefinition<"us">;
    plateauTime?: DynamicChannelProgramParameterDefinition<"us">;
}


type StimParamsValues = {
  [K in keyof DynamicChannel]: number
}

interface DynamicProgramConfigurationParameters {
  phaseRepetition?: ChannelProgramParameterDefinition<"number">;
  maxSupportedChannels?: ChannelProgramParameterDefinition<"number">;
  phases?: ({
      endRelaxTime?: ChannelProgramParameterDefinition<"s">;
      channels?: DynamicChannel;
  } | null)[];
}

@Component({
  selector: 'sba-stim-configuration-setting-adjustment-component',
  templateUrl: './stim-configuration-setting-adjustment-component.component.html',
  styleUrls: ['./stim-configuration-setting-adjustment-component.component.scss'],
})
export class StimConfigurationSettingAdjustmentComponentComponent implements OnInit {
  private hasDynamicChanged: boolean = false;
  program: DeepReadonly<ProgramConfiguration>;
  dynamicProgramParameters: DynamicProgramConfigurationParameters;
  parameters: ProgramConfigurationParametersValues;
  channels?: SelectedMuscle[] | null;
  treatmentDurations = STIM_DURATIONS;
  isAlreadySet = false;
  durationModifier: number;
  isEMGTriggered: boolean;
  

  channelPurposeToTranslationMap = {
    primary: 'EMS',
    trigger: 'EMG',
    both: 'EMS+EMG',
  };

  constructor(
    public dialogRef: MatDialogRef<StimConfigurationSettingAdjustmentComponentComponent>,
    private readonly authService: AuthService,
    private readonly cdr: ChangeDetectorRef,
    private readonly translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      program: DeepReadonly<ProgramConfiguration>;
      channels?: SelectedMuscle[] | null;
      existingParameters?: ProgramConfigurationParametersValues;
      allowEmgChannels?: boolean;
      allowEmgEmsChannels?: boolean;
      durationFixedModifier?: number;
    }
  ) {
    dialogRef.disableClose = true;
    this.program = data.program;
    this.durationModifier = data.durationFixedModifier ?? 0;
    this.channels = data.channels;

    if (!this.program.parameters) {
      this.dialogRef.close(undefined);
      return;
    }

    this.parameters = data.existingParameters ?? {
      phases: this.program.parameters.phases.map(v => (!v ? null : { channels: {} })),
    };

    this.initializeDynamicProgramParameters();
    this.calculateDynamicParameters();
    this.isEMGTriggered = this.program.phases.some(phase => phase.needsTrigger);
  }

  private setValuesForParameter(i: string, k: keyof DynamicChannel, values: DynamicChannelValue[]): void {
    for (let vi = 0; vi < values.length; vi++) {
      if( this.dynamicProgramParameters.phases[i].channels[k].values[vi].disabled !== values[vi].disabled) {
        this.hasDynamicChanged = true;
        break;
      }
    }

    this.dynamicProgramParameters.phases[i].channels[k].values = values;
  }

  private getValuesForParameter(i: string, k: keyof DynamicChannel): DynamicChannelValue[] {
    return this.dynamicProgramParameters.phases[i].channels[k].values;
  }

  private setDefaultValueForParameter(i: string, k: keyof DynamicChannel, value: number): void {
    if( this.dynamicProgramParameters.phases[i].channels[k].default !== value) {
      this.hasDynamicChanged = true;
    }
    
    if( this.dynamicProgramParameters.phases[i].channels[k] ) {
      // FIXME - should i store value if there is no such parameter in the dynamic parameters?
      this.dynamicProgramParameters.phases[i].channels[k].default = value;
      this.parameters.phases[i].channels[k] = value;
    }
  }

  /*
   * Returns the default value for a parameter. 
   * If the parameter is not defined in the dynamic parameters, the default value from the program is returned.
   */
  private getDefaultValueForParameter(i: string, k: keyof DynamicChannel): number {
    if( this.dynamicProgramParameters.phases[i].channels[k] ) {
      return this.dynamicProgramParameters.phases[i].channels[k].default;
    }
    return Math.max(...this.program.defaultChannelValues.map(c => c[k]));
  }

  private initializeDynamicProgramParameters(): void {
    this.dynamicProgramParameters = {
      phases: this.program.parameters.phases.map((v, i) => {
        if (!v) return null;

        const keys = Object.keys(v.channels) as (keyof typeof v.channels)[];
        const channels = keys.reduce((obj, k) => {
          const channelValues = v.channels[k].values.map(v => {
            return {
              value: v,
              disabled: false,
            };
          });
          return {
            ...obj,
            [k]: {
              ...v.channels[k],
              values: channelValues,
            },
          };
        }, {} as DynamicChannel);
        return { ...v, channels };
      }),
    };
  }

  calculateDynamicParameters(): void {
    // walk through all parameters
    do {
      this.hasDynamicChanged = false;
      for (const phase in this.dynamicProgramParameters.phases) {
        for (const key in this.dynamicProgramParameters.phases[phase].channels) {
          const channelKey = key as keyof DynamicChannel;

          const defaultWidth = this.getDefaultValueForParameter(phase,'pulseWidth');
          const defaultFrequency = this.getDefaultValueForParameter(phase,'pulseFrequency');

          const values = this.getValuesForParameter(phase, channelKey).map(v => {
            const params: StimParamsValues = {
              pulseWidth: key === 'pulseWidth' ? v.value : this.parameters.phases[phase].channels.pulseWidth ?? defaultWidth,
              pulseFrequency: key === 'pulseFrequency' ? v.value : this.parameters.phases[phase].channels.pulseFrequency ?? defaultFrequency,
              riseTime: this.parameters.phases[phase].channels.riseTime ?? this.getDefaultValueForParameter(phase, 'riseTime'),
              fallTime: this.parameters.phases[phase].channels.fallTime ?? this.getDefaultValueForParameter(phase, 'fallTime'),
              plateauTime: this.parameters.phases[phase].channels.plateauTime ?? this.getDefaultValueForParameter(phase, 'plateauTime'),
              pauseTime: this.parameters.phases[phase].channels.pauseTime ?? this.getDefaultValueForParameter(phase, 'pauseTime'),
              offset: this.parameters.phases[phase].channels.offset ?? 0,
            }
            if( key === 'pulseWidth' || key === 'pulseFrequency') {
              const disabled = !this.canStellaAcceptParameter(params);
              return {
                ...v,
                disabled,
              };
            } else {
              return v;
            }
          });
          // setValuesForParameter can set this.hasDynamicChanged to true
          this.setValuesForParameter(phase, channelKey, values);
          if( this.hasDynamicChanged) {
            if( channelKey === 'pulseWidth' && !values.find(v => v.value === defaultWidth && !v.disabled)) {
              this.setDefaultValueForParameter(phase,'pulseWidth',this.getNearestDefaultValueForChannel(values, defaultWidth));
            }
            if( channelKey === 'pulseFrequency' && !values.find(v => v.value === defaultFrequency && !v.disabled)) {
              this.setDefaultValueForParameter(phase,'pulseFrequency',this.getNearestDefaultValueForChannel(values, defaultWidth));
            }
          }
        }
      }
    } while(this.hasDynamicChanged);
  }

  private getNearestDefaultValueForChannel(channelValues: DynamicChannelValue[], currentDefaultValue: number): number {
    return channelValues
      .filter(cv => !cv.disabled)
      .map(cv => cv.value)
      .sort((a, b) => {
        const aDistance = Math.abs(currentDefaultValue - a);
        const bDistance = Math.abs(currentDefaultValue - b);
        if (aDistance < bDistance) return -1;
        if (aDistance > bDistance) return 1;
        return 0;
      })[0];
  }

  private gcd(...arr) {
    const _gcd = (x, y) => (!y ? x : this.gcd(y, x % y));
    return [...arr].reduce((a, b) => _gcd(a, b));
  };

  private commonPeriod(period: number, burstInterval?: number, startOffset?: number) {
    // The function uses a simplification that assumes that the same program is running on each electrostimulation channel
    let result = 0;
      result = this.gcd(period, result);
      result = burstInterval ? this.gcd(burstInterval, result): result;
      result = startOffset ? this.gcd(startOffset, result): result;
    return result;
  }

  private canStellaAcceptParameter(params: StimParamsValues): boolean {
    const period = Math.round(1e6 / params.pulseFrequency);
    const burstInterval = Math.round((params.riseTime + params.fallTime + params.plateauTime + params.pauseTime) / period) * period;
    const numberOfChannels = this.channels.length;
    const stellaSwitchTime = 500;
    const commonPeriod = this.commonPeriod(period, burstInterval, params.offset);
    return (params.pulseWidth * 2 + stellaSwitchTime) * numberOfChannels < commonPeriod;
  }

  ngOnInit(): void {
    const role = this.authService.currentUser.role;
    if (role === Role.PATIENT) {
      this.isAlreadySet = true;
    }
  }

  changeVal(event, field: string) {
    const path = field.split('.');
    let obj = this.parameters as any;

    for (let i = 0; i < path.length - 1; i++) {
      obj = obj[path[i]];
    }

    obj[path[path.length - 1]] = event.value;
    this.calculateDynamicParameters();
    this.cdr.detectChanges();
  }

  changeChannelPurpose(event: any, channel: SelectedMuscle) {
    const purpose = event.value as 'primary' | 'trigger' | 'both';

    this.channels.forEach(v => (v.purpose = 'primary'));

    channel.purpose = purpose;
    console.log(channel);
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(this.parameters);
  }

  deny() {
    this.dialogRef.close(false);
  }

  generateProgramDurationInfo(repetition: number): string {
    if (!this.isEMGTriggered) {
      const seconds = repetition * this.program.phases.map(v =>
        Math.max(...this.program.defaultChannelValues.map(d => ({
          ...d,
          ...v.channels.find(c => c.channelIndex === d.channelIndex)
        })).map(v => v.runTime))
      ).reduce((prev, next) => prev + next, 0) * 1e-6;

      const minutesTranslation = `${this.translateService.instant('common.units.minutes')}`;
      const secondsTranslation = `${this.translateService.instant('common.units.seconds')}`;

      return ' (' + Math.floor(seconds / 60) + ' ' + minutesTranslation + (Math.floor(seconds % 60) !== 0 ? ' ' + Math.floor(seconds % 60) + ' ' + secondsTranslation : '') + ')';
    }
    return '';
  }

  getPauseTime(phaseIndex) {
    const phase = this.program.phases[phaseIndex];
    const defaultChannelValues = this.program.defaultChannelValues;

    return Math.min(
      ...phase.channels.map((channel, channelIndex) => {
        const pauseTime = (channel.pauseTime ?? defaultChannelValues[channelIndex].pauseTime);

        return pauseTime * 1e-6;
      })
    );
  }
}
